@mixin  text-info {
  font-family: $sfProTin;
  font-size: 16px;
}
.section-usage {
  &.verify {
    margin-top: 0;
  }
  .services__verification {
      .verification__row {
        height: 64px;
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        .verification__wrapper {
          display: flex;
          align-items: center;
          .verification__icon {
            height: 24px;
            width: 24px;
            background-image: url('../../../images/lock__sprite.svg');
            background-size: cover;
            background-repeat: no-repeat;
            &.activeIcon {
              background-position: bottom;
            }
          }
          .verification__fields {
            display: flex;
            margin-left: 25px;
            margin-right: 25px;
            .field__number {
              border: 1px solid #747474;
              border-radius: 6px;
              width: 27px;
              height: 42px;
              font-family: $sfProRegular;
              font-size: 24px;
              text-align: center;
              outline: none;
              &.field__number:not(:first-child) {
                margin-left: 16px;
              }
            }
          }
        }
      }
      .verification__timer {
        @include text-info;
        text-align: center;
        margin-top: 36px;
        margin-bottom: 36px; 
        .verification__troubles {
          @include text-info;
          text-align: left;
          cursor: pointer;
          .troubles__info {
            display: block;
            padding-right: 30px;
            span {
              padding-left: 4px;
            }
          }
          .troubles__edit {
            font-size: 17px;
            color: #747474;
            text-align: center;
            font-family: $sfProRegular;
            margin-top: 24px;
            margin-bottom: 24px;
            cursor: pointer;
          }
        }
      }
      .verification__repeat_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - (16px + 16px));
        height: 44px;
        max-width: 343px;
        cursor: pointer;
        border-radius: 70px;
        color: #fff;
        font-size: 18px;
        margin: 0 auto;
        font-family: $mediumGhotic;
        background: linear-gradient(353deg, #530094 50%, #530094 66%, #5800B6 88%, #5F00C4 100%);
        box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.2);
        div {
          white-space: nowrap;
        }
      }
  }
}