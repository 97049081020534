@media (max-width: 373px) {
  .section-usage  {
    .services__time {
      .grid__time  {
        grid-column-gap: 0;
        .grid__time_picker {
          width: 65px;
        }
      } 
    }
  }
}