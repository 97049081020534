@media (min-width: 497px) {
  .welcome-section {
    .section__wrapper {
      .section__title {
        .section__info {
          text-align: center;
        }
      }
    }
  }
}
@media (min-width: 547px) {
  .welcome-section {
    .section__wrapper {
      .section__title {
        div {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}