@mixin counters-fonts {
  font-family: $sfProRegular;
  font-size: 13px;
  color: #747474;
  text-transform: uppercase;
}
.error_absolute {
  .block__error {
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #e93f47;
    opacity: 0;
    z-index: 1000000;
    &.active__error {
      opacity: 1;
      transition-duration: 0.5s;
    }
    .error__message {
      color: #fff;
      font-size: 13px;
      font-weight: 200;
      font-family: $sfProRegular;
      text-transform: uppercase;
    }
  }
}
.block__error {
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #fff;
  z-index: 1000000;
  &.active__error {
    opacity: 1;
    background-color: #e93f47;
    transition-duration: 0.5s;
  }
  .error__message {
    color: #fff;
    font-size: 13px;
    font-weight: 200;
    font-family: $sfProRegular;
    text-transform: uppercase;
  }
}
.section-usage {
  &.no_margin {
    margin-top: 0;
  }
  margin-top: 32px;
  margin-bottom: 32px;
    .services {
      width: 100%;
      &__steps {
        display: flex;
        .steps__counter {
          @include counters-fonts;
        }
        .steps__title {
          @include counters-fonts;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .services__date {
      @include content;
    }
    .service__list {
      padding-top: 6px;
      padding-bottom: 6px;
      .product {
        cursor: pointer;
        display: flex;
        user-select: none;
        position: relative;
        justify-content: space-between;
        align-items: center;
        // &:first-child {
        //   border-top: 1px solid #e0e0e0;
        // }
        border-bottom: 1px solid #e0e0e0;
        padding-top: 13px;
        padding-bottom: 13px;
        .product_flex {
          display: flex;
          align-items: center;
          width: 100%;
        }
        .dashboard__checking {
          max-width: 13px;
          max-height: 10px;
          height: 10px;
          width: 13px;
          margin-left: auto;
          &.checking_active {
            background-image: url('../../images//checkin.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        &__decor {
          background-image: url('../../images/sprite.svg');
          background-size: cover;
          background-repeat: no-repeat;
          height: 24px;
          width: 24px;
          transition-property:  background-image;
          background-position-y: bottom;
          &.is_choosing {
            background-position-y: inherit;
          }
        }
        &__dashboard {
          margin-left: 18px;
          max-width: calc(100% - 32px - 24px - 32px);
          min-width: 200px;
          .dashboard__title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            box-orient: vertical;
            overflow-wrap: break-word;
            font-family: $sfProRegular;
            font-size: 17px;  
            white-space:normal;
            font-weight: 200;
            max-height: auto;
           }
          .dashboard__marketing {
            display: flex;
            align-items: center;
            color: #747474;
            list-style-type: none;
            font-size: 17px;
            font-family: $sfProRegular;
            .marketing__price {
              display: flex;
              align-items: center;
              span {
                height: 5px;
                border-radius: 50%;
                width: 5px;
                margin-left: 5px;
                margin-right: 5px;
                background-color: #747474;
                display: inline-block;
              }
            }
          }
        }
      }
    }
}