//file to common elements exp: .input
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body,
#root,
.page {
  min-height: 100%;
  width: 100%;
  height: 100%;
  // height: 100vh;
  // position: relative;
  font-family: $sfProTin;
}

div {
  -webkit-tap-highlight-color: transparent;
}
.preload {
  background: url("../../images/sprite.svg"), url("../../images/checkin.svg"),
    url("../../images/sprite__name.svg"), url("../../images/phone__sprite.svg"),
    url("../../images/Check_small.svg"), url("../../images/location.svg"),
    url("../../images/currency.svg"), url("../../images/complete.svg"),
    url("../../images/checkin.svg"), url("../../images/clock.svg");
}

.wrapper {
  min-height: 100%;
  width: 100%;
}
.wrapper_in_block {
  padding-left: $leftPadWrapp;
  padding-right: $rightPadWrapp;
}
.react-calendar__navigation {
  display: none !important;
}
.content__button {
  @include flex-center;
  width: calc(100% - (#{$leftPadWrapp} + #{$rightPadWrapp}));
  height: $heightTermsButton;
  max-width: 343px;
  cursor: pointer;
  background: linear-gradient(
    353deg,
    rgba(253, 194, 95, 0.9780287114845938) 10%,
    rgba(251, 176, 64, 1) 82%
  );
  border-radius: 70px;
  color: #fff;
  font-size: 17px;
  font-family: $sfProRegular;
  position: fixed;
  bottom: $buttonBottomPad;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.disable_slide {
  opacity: 0.4;
}
.react-add-to-calendar {
  display: none;
}
//slick slider
.slick-slide {
  opacity: 0.4;
}
select {
  font-size: 16px;
}
.slick-slide {
  &.slick-active {
    &.slick-center {
      opacity: 1;
    }
  }
}

.wrapper__slider {
  display: flex;
  transition: 0.5s;
}
.wrapper__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .modal__wrapper {
    background-color: #fff;
    width: 300px;
    margin: 0 auto;
    padding: 10px;
    .modal__title {
      font-family: $sfProRegular;
      font-size: 16px;
      font-weight: 200;
      text-align: center;
    }
    .modal__description {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: $sfProRegular;
      font-size: 14px;
      font-weight: 200;
      text-align: center;
      display: block;
    }
    .again {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: $sfProRegular;
      font-size: 14px;
      font-weight: 200;
      color: #530095;
      cursor: pointer;
      text-align: center;
      display: block;
    }
    .modal__btns {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: space-around;
      .modal__btn {
        height: 100%;
        cursor: pointer;
        padding: 9px;
        font-family: $sfProRegular;
        font-size: 14px;
        font-weight: 200;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #530095;
        border-radius: 21px;
        color: #fff;
      }
    }
  }
}
.show_all {
  font-size: 17px;
  font-family: "SFProDisplay-Thin";
  color: #530095;
  z-index: 500;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}
.services {
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  .services__steps {
    display: flex;
    padding-top: 27px;
    .steps__counter {
      font-family: "SFProDisplay-Regular";
      font-size: 13px;
      color: #747474;
      text-transform: uppercase;
    }
    .steps__title {
      padding-left: 10px;
      padding-right: 10px;
      font-family: "SFProDisplay-Regular";
      font-size: 13px;
      color: #747474;
      text-transform: uppercase;
    }
    .back__button-wrapper {
      margin-bottom: 2px;
      margin-top: -2px;
      .back__button {
        background-color: #6e6d73;
        border-radius: 5px;
        border: none !important;
        color: #fff;
        padding-inline: 5px;
        padding-block: 1px;
        text-align: center;
        font-size: 13px;
        font-family: "SFProDisplay-Regular";
        margin-right: 10px;
        padding-left: 19px;
        cursor: pointer;
        .back__button-symbol {
          position: absolute;
          margin-left: -16px;
        }
      }
    }
  }
}
// captchacaptcha
.calenader__window {
  margin-top: 46px;
}
.bg__layout {
  height: 100%;
}
/*out fills*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #ffff inset !important;
  background-color: #ffff !important;
  background-clip: content-box !important;
}
.preloaderFonts {
  opacity: 0;
  height: 0;
  .bold__font {
    font-family: $sfProBold;
  }
  .regula__font {
    font-family: $sfProRegular;
  }
  .thin__font {
    font-family: $sfProTin;
  }
  .light__font {
    font-family: $sfLight;
  }
  .ghotic__font {
    font-family: $mediumGhotic;
  }
  .averin__font {
    font-family: $averinMed;
  }
}
.preloader__spinner {
  width: 200px;
  margin: 0 auto;
}
.preloader {
  &.transparent {
    background: transparent !important;
  }
}

.spin {
  color: #747474 !important;
}
.zindexHelp {
  z-index: 200 !important;
}
.hidden {
  opacity: 0;
}

.d-none {
  display: none !important;
}

.grid__time_picker {
  &_heap {
    display: none !important;
    &_show {
      display: flex !important;
    }
  }
}