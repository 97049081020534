.section-usage  {
  .services__time {
    @include content;
    .grid__time {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 65px;
      grid-column-gap: 20px;
      .grid__time_picker {
        width: 100%;
        height: 44px;
        background: #E0E0E0;
        border-radius: 22px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        font-family: $sfProTin;
        &.active {
          background-color: #530095;
          color: #fff;
        }
        &.disable {
          background: #EFEFF4;
          color: #747474;
        }
        &.hidden {
          pointer-events: none;
          opacity: 0;
        }
      }
      &.ampm {
        .grid__time_picker {
          font-size: 15px;
        }
      }
    }
  }
}

.no_appointments__text{
  text-align: center;
  margin-top: 10%;
}