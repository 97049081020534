@media (max-width: 325px) {
  .calendar__window {
    .calendar__header {
			.calendar__corusel {
				.calendar__date {
					font-size: 15px;
				}
			}
    }
  }  
}