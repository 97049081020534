.section-usage {
    margin-bottom: 0;
    &.phone {
      margin-top: 0;
    }
    .services__contacts {
      .services__field {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #e0e0e0;
        .error_absolute {
          position: absolute;
          bottom: -10px;
          width: 100%;
        }
        border-bottom: 1px solid #e0e0e0;
        .wrapper_in_block {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .field__flex {
            display: flex;
            align-items: center;
            .field_remove_btn {
              border: none;
              height: 16px;
              background-color: #fff;
              border-radius: 50%;
              user-select: none;
              cursor: pointer;
              outline: none;
              .field__controller {
                cursor: pointer;
                height: 16px;
                width: 16px;
              }
            }
            .field__icon { 
              height: 24px;
              width: 24px;
              min-height: 24px;
              min-width: 24px;
              max-width: 24px;
              max-height: 24px;
              background-image: url('../../../images/sprite__name.svg');
              background-position: center;
              background-size: cover;
              background-position: bottom;
              &.activeFieldName {
                background-position-y: inherit;
              }
            }
            .field__icon_tel {
              height: 24px;
              width: 24px;
              min-height: 24px;
              min-width: 24px;
              max-width: 24px;
              max-height: 24px;
              background-image: url('../../../images/phone__sprite.svg');
              background-position: top;
              background-size: cover;
              &.activeFielTel {
                background-position-y: bottom;
              }
            }
            .field__wrapper {
              margin-left: 18px;
              margin-right: 18px;
              width: 65%;
              display: flex;
              flex-direction: column;
              position: relative;
              label {
                font-family: $sfProRegular;
                font-size: 10px;
                position: absolute;
                color: #747474;
                top: -19px;            
              }
              &.phone__wrapper {
                flex-direction: row;
                margin-left: 20px;
                margin-right: 18px;
                align-items: center;
                .phone__select {
                  font-size: 17px;
                  font-family: "SFProDisplay-Regular";
                  background: #EBEBEB;
                  border-radius: 8px;
                  color: #747474;
                  max-width: 170px;
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                  min-height: 25px;
                  max-height: 25px;
                  .select__current {
                    padding: 5px;
                    span {
                      width: 100%;
                      white-space: nowrap;
                      .country {
                        display: none;
                      }
                    }
                  }
                  select {
                    height: 25px;
                    border: none;
                    appearance: none;
                    outline: none;
                    opacity: 0;
                    text-align: -webkit-center;
                    font-size: 13px;
                    font-family: $sfProRegular;
                    background: #EBEBEB;
                    border-radius: 8px;
                    color: #747474;
                    top: 0;
                    position: absolute;
                    z-index: 25;
                    text-align-last: center;
                  }
                }
              }
              .field__input {
                font-family: $sfProRegular;
                font-size: 17px;
                border: none;
                outline: none;
                &::placeholder {
                  font-size: 17px;
                  font-family: $sfProRegular;
                  color: #747474;
                }
              }
            }
            .field__input_tel {
              width: 60%;
              font-family: $sfProRegular;
              font-size: 17px;
              border: none;
              outline: none;
              padding-left: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              &::placeholder {
                font-size: 17px;
                font-family: $sfProRegular;
                color: #747474;
              }
            }
          }
        }
      }
    }
}