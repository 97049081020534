.calendar__window {
  .calendar__header {
    width: 100%;
    position: relative;
    overflow-x: hidden;
    .corusel__window {
      height: 90px;
      width: 100%;
      border: 1px solid #000;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .calendar__corusel {
      position: relative;
      .calendar__date  {
        font-family: $sfProRegular;
        font-size: 17px;
        color: #000000;
        user-select: none;
        display: none;
        &.active__month {
          display: none;
          transition: 0.5s;
          cursor: pointer;
          &:focus {
            outline: none;
          }
        }
        &.dissable__slide {
          opacity: 0.5;
        }
        &.current {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        &.disable-pos_right {
          display: block;
          opacity: 0.5;
          position: absolute;
          right: -30px;
        }
        &.disable-pos_left {
          display: block;
          opacity: 0.5;
          position: absolute;
          left: -30px;
        }
      }
    }
  }
  .wrapper_in_block  {
    .table {
      padding-top: 14px;
      padding-bottom: 14px;
      width: 100%;
      display: grid;
      user-select: none;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(7, 40px);
      .table__head {
        text-align: center;
        font-family: $mediumGhotic;
        font-size: 13px;
        color: #757575;
        align-self: center;
      }
      .table__custom {
        align-self: center;    
        justify-self: center;
        .table__ceil {
          font-family: $averinMed;
          font-size: 12px;
          text-align: center;
          border-radius: 50%;
          transition-duration: 0.2s; 
          width: 26px;
          height: 26px;
          cursor: pointer;
          justify-self: center;
          position: relative;
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
          &.disable {
            opacity: 0;
          }
          &.close__date {
            color: #9B9B9B;
            opacity: 0.6;
          }
          &.choosing {
            color: #fff;
            background-color: #530095!important;
          }
          &.td {
            color: #ca4f40;
          }
          &.today {
            color: #fff;
            border-radius: 50%;
            background-color: rgba(202,79,64,1);
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}