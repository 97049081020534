
// .footer {
//   .wrapper_in_block {
  .wrapper_in_bloc{
    display:flex;
       justify-content: center;
  }
  .footer__bt {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 44px;
      max-width: 343px;
      cursor: pointer;
      background: linear-gradient(353deg, rgba(253, 194, 95, 0.978029) 10%, #fbb040 82%);
      border-radius: 70px;
      color: #fff;
      font-size: 17px;
      font-family: "SFProDisplay-Regular";
      margin: 0 auto;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.2);
    }
    .footer__controllers {
      width: 100%;
      margin-top: 29px;
      text-align: center;
      cursor: pointer;
      font-family: $sfProRegular;
      font-size: 17px;
      color: #747474;
      &:last-child {
        margin-bottom: 29px;
      }
  //   }
  // }
}

@mixin headerbord-fonts {
  font-family: $sfProTin;
  font-size: 17px;
}
@mixin  more-btn {
  font-size: 17px;
  font-family: $sfProTin;
  color: #530095;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
}
@mixin  font-total {
  font-size: 17px;
  font-family: $sfProTin;
}

.modalOffert{

  width: 100%;
  height: 100%;
  margin: auto;
  top: 121vh; left: 0; bottom: 0; right: 0;
  background-color: #fff;
  overflow-x: hidden;
  overflow: hidden;
  overflow-y: scroll;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.wrapper_buttonOffert{
  display: flex;
  align-items : center;
}

.buttonOffert{
  display: block;
  float: left;
  margin-left: auto;
  margin-right: auto;
  width: 180px;
  height: auto;
  border-radius: 15px;
  margin-bottom: 20px;
  background-color:#530095;
  text-align: center;
}

.header-usage {
  width: 100%;
  min-height: 92px;
  position: sticky;
  top: 0;
  z-index: 500;
  &.auto {
    height: auto;
  }
  .header__wrapper {
    padding-top: 10px;
    padding-bottom: 13px;
    min-height: 92px;
    display: flex;
    justify-content: space-between;
    background-color: #EFEFF4;
    .headerbord__table_result_wrap {
      span {
        padding-left: 0px !important;
        padding-right: 5px;
      }
    }
    &.last_page {
      .headerbord {
        .headerbord__active_services {
          .active_services__list {
              li {
                margin-top: 23px;
              }
          }
        }
      }
    }
    .headerbord {
      width: 100%;
      max-width: calc(100% - 100px);
      &__service {
        @include headerbord-fonts;
      }
      &__user_name {
        font-family: $sfLight;
        font-size: 17px;
        margin-top: 3px;
        margin-bottom: 3px;
      }
      &__state {
        margin-top: 3px;
        @include headerbord-fonts;
      }
      &__active_services {
        .active_services__total {
          display: flex;
          margin-top: 26px;
          .active_services__result {
            @include font-total;
            &:not(:first-child) {
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
        .active_services__list {
          margin-top: 5px;
          margin-bottom: 5px;
          list-style-type: none;
          li {
            position: relative;
            display:  flex;
            @include font-total;
            &:not(:first-child) {
              margin-top: 23px;
              min-width: 300px;
            }
            &:first-child {
              .table_result__moreBtn {
                @include more-btn;
                user-select: none;
                display: inline-block;
              }
            }
            .item__wrapper {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 74%;
            }
            .delete_item {
              padding-right: 13px;
              img {
                user-select: none;
                cursor: pointer;
                height: 12px;
                width: 12px;
              }
            }
          }
        }
      }
      &__table_result {
        display: flex;
        font-size: 17px;
        font-family: $sfProTin;
        white-space: nowrap;
        p {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        span {
          padding-left: 5px;
        }
        &:not(:first-child) {
          margin-top: 3px;
          margin-bottom: 3px;
        }
        .table_result__moreBtn {
          @include more-btn;
          user-select: none;
        }
        .table_result__sum {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .escape__block {
        position: absolute !important;
      }
      .escape__block__invisible {
        visibility: hidden !important;
      }
      @media (max-width: 340px) {
        .escape__block__invisible {
          font-size: 10px !important;
        } 
      }
    }
    .next_link {
      text-decoration: none;
      max-height: 44px;
      margin-top: 5px;
    }

.linkPolice{
  width: 250px;
  background-color: rgb(9, 255, 0);
}

.headerbord_wrapper{
  width: 100%;
}

.wrapper_next_link{
  margin-left: 20px;
}

    .next_button {
      @include flex-center;
      user-select: none;
      outline: 0;
      margin-top: 15px;
      width: 84px;
      height: 44px;
      border-radius: 50px;
      // margin-left: auto;
      cursor: pointer;
      border: 1px solid #747474;
      color: #747474;
      font-size: 17px;
      font-family: $sfProRegular;
      &.activeBtnNext {
        background: #530095;
        border: none;
        color: #fff;
      }
    }
  }
}

.headerbord .termsOfUse__link:link, .termsOfUse__link:visited {
  color: #007AFF;
  text-decoration: none;
}