.page-ready {
  .header {
    width: 100%;
    background-color: #5DB37E;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.25);
    .wrapper_in_block  {
      .header__wrapper {
        padding-right: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        .header__title {
          font-family: $sfProBold;
          font-size: 34px;
          color: #FFFFFF;
        }
        .header__legend {
          margin-top: 5px;
          font-family: $sfProTin;
          font-size: 21px;
          color: #FFFFFF;
          line-height: 20px;
        }
      }
    }
  }
  .order {
    min-height: 100%;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
   .wrapper_in_block {
    .order__info {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 12px;
      padding-bottom: 12px;
      .info__image {
        height: 24px;
        width: 24px;
        max-height: 24px;
        max-width: 24px;
        min-width: 24px;
        min-height: 24px;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .info__label {
        font-size: 17px;
        font-family: $sfProRegular;
        margin-left: 32px;
        margin-right: 32px;
        // white-space: nowrap;
        overflow: hidden;
        overflow-wrap: break-word;
        div {
          text-overflow: ellipsis;
          overflow: hidden;
          span {
            padding-left: 2px;
          }
        }
      }
      &.order__info_master {
        .info__image {
          background-position: bottom;
          background-image: url('../../images/sprite__name.svg');
        }
      }
      &.order__info_master_location {
        .info__image {
          background-position: center;
          background-image: url('../../images/location.svg');
        }
      }
      &.order__info_time {
        .info__image {
          background-position: center;
          background-image: url('../../images/clock.svg');
        }
      }
      &.order__info_cost {
        .info__image {
          background-position: center;
          background-image: url('../../images/currency.svg');
        }
        &.en {
          .info__image {
            background-image: url('../../images/currency_en.png');
          }
        }
      }
      &.order__info_service {
        .info__image {
          background-image: url('../../images/sprite.svg');
          background-position: bottom;
        }
        .info__label .info__deep {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-family: $sfProRegular;
          span {
            height: 5px;
            border-radius: 50%;
            width: 5px;
            margin-left: 5px;
            margin-right: 5px;
            background-color: #000;
            display: inline-block;
          }
        }
      }
    }
   }
  }
  .footer {
    .wrapper_in_block {
      .footer__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        max-width: 343px;
        cursor: pointer;
        background: linear-gradient(353deg, rgba(253, 194, 95, 0.978029) 10%, #fbb040 82%);
        border-radius: 70px;
        color: #fff;
        font-size: 17px;
        font-family: "SFProDisplay-Regular";
        margin: 0 auto;
        box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.2);
      }
      .footer__controllers {
        width: 100%;
        margin-top: 29px;
        text-align: center;
        cursor: pointer;
        font-family: $sfProRegular;
        font-size: 17px;
        color: #747474;
        &:last-child {
          margin-bottom: 29px;
        }
      }
    }
  }
}