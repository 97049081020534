$sfProRegular: 'SFProDisplay-Regular';
$greyColor: #7f7f84;
$sfProBold: 'SFProDisplay-Bold';
$sfProTin: 'SFProDisplay-Thin';
$sfLight: 'SFProDisplay-Light';
$mediumGhotic: 'AppleSDGothicNeo-Medium';
$averinMed: 'Avenir-Medium';
$leftPadWrapp: 16px;
$rightPadWrapp: 16px;
$buttonBottomPad: 40px;
$heightTermsButton: 44px;