.master__item {
    text-decoration: none;
    color: #000;
    
    .master__flex {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .master__dashboard {
        display: flex;
    }

    .master__avatar {
        height: 24px;
        width: 24px;
        min-height: 24px;
        min-width: 24px;
        max-width: 24px;
        max-height: 24px;
        background-image: url('../../images/sprite__name.svg');
        background-position: center;
        background-size: cover;
        background-position: bottom;
        &.activeFieldName {
          background-position-y: inherit;
        }
    }

    .master__next-step {
        height: 12px;
        width: 12px;
        border-right: solid 2px $greyColor;
        border-bottom: solid 2px $greyColor;
        transform: rotate(-45deg)
    }
}
