@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-center-and-spc-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin content {
  margin-top: 6px;
  margin-bottom: 6px;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  } 
}
