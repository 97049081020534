.welcome-section {
  width: 100%;
  height: calc(100% - 45%);
  .section__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    .captcha {
      width: 343px;
      position: fixed;
      bottom: 40px;
      cursor: pointer;
      left: 50%;
      transform: translateX(-50%);
    }
    .section__title {
      font-size: 36px;
      font-family: $sfProTin;
      margin: 0 auto;
      div {
        text-align: center;
      }
      .section__info {
        font-size: 16px;
        font-family: $sfProTin;
        margin-top: 17px;
        margin-bottom: 17px;
        text-align: left;
        padding-left: 4px;
        line-height: 19px;
        margin-left: 30px;
        padding-right: 4px;
      }
    }
  }
}