@media (min-width: 400px) {
  .section-usage {
    .services__contacts  {
      .services__field {
        .wrapper_in_block {
          .field__flex {
            .field__wrapper {
              width: calc(100% - 50px);
            }
          }
        }
      }
    }
  } 
}
@media (max-width: 351px) {
  .section-usage {
    .services__contacts  {
      .services__field {
        .wrapper_in_block {
          .field__flex {
            .field__input_tel {
              width: 75%;
            }
            .field__wrapper {
              margin-left: 20px;
              &.phone__wrapper {
                margin-left: 20px;
                .phone__select {
                  .select__current {
                    span {
                      .country {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } 
}