.wrapper_bot{ 
  display: flex;
  align-items: center;
}
.antiBotClick{
  display: flex;
        justify-content: center;
        align-items: center;
        width: 16em;
        height: 44px;
        max-width: 343px;
        cursor: pointer;
        background: linear-gradient(353deg, rgba(253, 194, 95, 0.978029) 10%, #fbb040 82%);
        border-radius: 70px;
        color: #fff;
        font-size: 17px;
        font-family: "SFProDisplay-Regular";
        margin: 0 auto;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.header-welcome {
  padding-top: 36px;
  padding-bottom: 36px;
  .welcome__bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .welcome__logo {
      width: 170px;
      height: 20px;
    }
    .welcome__download__link {
      display: block;
      z-index: 300;
      .welcome__download__logo {
        margin-left: 26px;
        width: 118px;
        height: 38px;
      }
    }
  }
}