@font-face {
    font-family: "SFProDisplay-Regular";
    src:
      local('SFProDisplay-Regular'),   
      url("../fonts/FontsFree-Net-SFProDisplay-Regular.woff") format("woff"), /* Modern Browsers */
      url("../fonts/FontsFree-Net-SFProDisplay-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: "SFProDisplay-Thin";
    src:
      local('SFProDisplay-Thin'),  
      url("../fonts/SF-Pro-Display-Thin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/SF-Pro-Display-Thin.otf") format("opentype"), /* Open Type Font */
      url("../fonts/SF-Pro-Display-Thin.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/SF-Pro-Display-Thin.woff") format("woff"), /* Modern Browsers */
      url("../fonts/SF-Pro-Display-Thin.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AppleSDGothicNeo-Medium';
    src:
         local('AppleSDGothicNeo-Medium'),    
         url('../fonts/AppleSDGothicNeo-Medium.svg#AppleSDGothicNeo-Medium') format('svg'),
         url('../fonts/AppleSDGothicNeo-Medium.ttf') format('truetype'),
         url('../fonts/AppleSDGothicNeo-Medium.woff') format('woff'),
         url('../fonts/AppleSDGothicNeo-Medium.woff2') format('woff2');
    font-style: normal;
}
@font-face {
    font-family: "SFProDisplay-Light";
    src:
      local('SFProDisplay-Light'),   
      url("../fonts/SF-Pro-Display-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/SF-Pro-Display-Light.otf") format("opentype"), /* Open Type Font */
      url("../fonts/SF-Pro-Display-Light.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/SF-Pro-Display-Light.woff") format("woff"), /* Modern Browsers */
      url("../fonts/SF-Pro-Display-Light.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: "Avenir-Medium";
  src:
    local('Avenir-Medium'), 
    url("../fonts/Avenir-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Avenir-Medium.otf") format("opentype"), /* Open Type Font */
    url("../fonts/Avenir-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Avenir-Medium.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Avenir-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SFProDisplay-Bold";
  src:
    local('SFProDisplay-Bold'),  
    url("../fonts/FontsFree-Net-SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/FontsFree-Net-SFProDisplay-Bold.otf") format("opentype"), /* Open Type Font */
    url("../fonts/FontsFree-Net-SFProDisplay-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/FontsFree-Net-SFProDisplay-Bold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/FontsFree-Net-SFProDisplay-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}  