@media (max-width: 364px) {
  .notfound-section {
    .notfound-wrapper {
      .section__title {
        font-size: 22px;
      }
      .section__info  {
        .info__preview {
          font-size: 14px;
        }
        ul {
          font-size: 14px;
        }
      }
    }
  } 
}