.privacy {
    .wrapper__privacy {
      padding: 0 18px;
      width: 100%;
  }
  a {
    word-break: break-all;
  }
  
  .header_privacy {
      padding-top: 28px;
      padding-bottom: 6px;
      border-bottom: 1px solid  #E0E0E0;
      .wrapper__privacy {
          h1 {
              font-family: $sfProRegular;
              font-size: 13px;
              color: #747474;
              font-weight: 300;
          }
      } 
  }
   
  
  .wrapper__privacy {
      .content {
          padding-top: 12px;
          padding-bottom: 12px;
          .title {
              font-size: 13px;
              font-family: $sfProRegular;
              letter-spacing: 1px;
              &:not(:first-child) {
                  margin-top: 20px;
                  margin-bottom: 20px;
              }
          }
          .text {
              font-size: 12px;
              line-height: 18px;
              font-family: $sfProTin;
              margin-top: 10px;
              margin-bottom: 10px;
          }
      }
  }
  }