//file only to connect other components
@import '../font/fontawesome_5_11_2/scss/fontawesome.scss';
@import '../font/fontawesome_5_11_2/scss/solid.scss';


// fonts 
@import './fonts';
// variables 
@import './variables'; 
// mixins  
@import './_mixins'; 
//common
@import './components/common';

//components
@import './components//notFound/notfound';
@import './components//welcome/header';
@import './components//welcome/section';
@import './components//usageServices';
@import './components/usageMasterList';
@import './components//usageHeader';
@import './components/preloader';
@import './components/policy';
@import './components/useterms';
@import './components/calendar';
@import './components/timeOrder//timeOrder';
@import './components/servicesContacts/servicesContacts.scss';
@import './components/verification/verification.scss';
@import './components/ready';
//media
@import './components/media';
@import './components//media__header';
@import './components/notFound/medianotfound';
@import './components/notFound/medianotfound';
@import './components/welcome/media__welcome';
@import './components//timeOrder/media__timeOrder';
@import './components/servicesContacts/media__servicesContacts';
@import './components//verification/media__verification';
@import './components/media__calendar';

//hiding captcha badge 
.grecaptcha-badge { 
  visibility: hidden;
}