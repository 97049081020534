.page-useterms {
  .header-useterms {
    max-height: 90px;
    border-bottom: 1px solid #E0E0E0;
	&.second_policy_header {
	  margin-top: -20px;
	}
    .header__title {
      font-family: $sfProRegular;
      font-size: 14px;
      color: $greyColor;
      font-size: 13px;
      font-weight: 200;
      padding-top: 28px;
      padding-bottom: 6px;
      text-transform: uppercase;
    }
  }
  .content {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
    &__text {
      font-family: $sfProTin;
      font-size: 12pt;
      line-height: 14px;
      letter-spacing: 1px;
      line-height: 18px;
      p {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &:not(:first-child) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
		&:last-of-type {
			padding-bottom: calc(#{$buttonBottomPad} + #{$heightTermsButton} - 52px);
		}
    .content__full_rules {
      margin-top: 20px;
      margin-bottom: 20px;
      .terms__block {
        .context__header {
          font-family: $sfProRegular;
          font-size: 13px;
          letter-spacing: 1px;
          font-weight: bold;
        }
      }
    }
    &__link {
      text-decoration: none;
    }
  }
}
