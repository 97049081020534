@media (max-width: 351px) {
  .header-usage {
    .header__wrapper {
      .headerbord__table_result {
        font-size: 15px;
      }
      .headerbord  {
        div { font-size: 15px;}
      }
      .headerbord__active_services  {
        ul {
          li {
            font-size: 15px;
            .item__wrapper {
              max-width: 30%!important;
            }
          }
        }
        .active_services__total {
          p {
            font-size: 15px!important;
          }
        }
      }
    }
  }  
}
@media (min-width: 500px) {
  .header-usage {
    .header__wrapper  {
      .headerbord__active_services {
        .active_services__list {
          li {
            .item__wrapper {
              max-width: 100%;
            }
          }
        }
      }
    }
  }  
}