@media (max-width: 351px) {
  .section-usage {
    .service__list  {
      .product__dashboard  {
        max-width: 200px;
        margin-left: 17px;
        .dashboard__marketing {
          font-size: 15px;
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .page {
    .wrapper {
      width: 960px;
      margin: 0 auto;
    }
  }
}


