@mixin infoText {
  font-size: 16px;
  font-family: $sfProTin;
  margin-top: 20px;
}
.notfound-section {
    width: 100%;
    height: calc(100% - 200px);
    .notfound-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .section__title {
        font-size: 36px;
        font-family: $sfProTin;
      }
      .section__info {
        .info__preview {
          @include infoText;
        }
        ul {
          @include infoText;
          list-style-type: none;
          margin-top: 6px;
          margin-bottom: 11px;
          li {
            display: flex;
            line-height: 19px;
            &:not(:first-child) {
              margin-top: 6px;
            }
            span{
              &::before {
                content: "\2022"; 
                color: #000; 
                font-weight: bold; 
                display: inline-block; 
                width: 12px; 
              }
            }
          }
        }
      }
      
    }
  }